import React from "react";
import { Titulos, PoliticaBanner, TextGrid } from "./Politica.js";

export function Politica() {
 
  return (
    <>
    <Titulos>Política de Privacidade</Titulos>
    <PoliticaBanner maxWidth='lg'> 
<TextGrid item xs={12} sm={12} md={12}>
<h1>Quem somos</h1>

<p>
Você pode saber mais sobre nós na seção “sobre”, “quem somos” ou “empresa” neste site. 
Você pode entrar em contato conosco pela seção “fale conosco” ou “contato” neste site.
</p>

<h1>Comentários e formulários de contato</h1>

<p>
Quando os visitantes deixam comentários no site, coletamos os dados mostrados no formulário de comentários, além do endereço de IP e de dados do navegador do visitante, para auxiliar na detecção de spam. O mesmo vale para formulários de contato ou fale conosco, ou qualquer forma de interação via formulário, onde você informa algum dos seus dados.

Estas informações podem ter caráter anônimo, como estatísticas gerais de utilização do site, ou caráter identificado, caso você navegue conectado (logado) por meio do seu usuário e senha. Estas formas de rastreamento são inerentes ao funcionamento do site, e necessárias à segurança e controle. Algumas tecnologias são aplicadas por meio de plugins e serviços de terceiros, ou por conteúdo incorporado, de forma que estão sujeitas aos termos de privacidade destes serviços, por exemplo Google Analytics e exibição de anúncios direcionados.

Comentários públicos de visitantes podem ser marcados por um serviço automático de detecção de spam.
</p>


<h1>Cookies e tecnologias semelhantes</h1>

<p>
Algumas funcionalidades deste site requerem uso de cookies ou tecnologias semelhantes, como controle de acesso, segurança, estatísticas de utilização e configurações. Ao navegar neste site você consente com a utilização destas tecnologias, nos termos desta política.

Caso não concorde com isso, não utilize este site, ou desative o acesso a estas tecnologias nas configurações de privacidade do seu navegador. Você também tem a opção de utilizar navegação “in private” ou “anônima”, se o seu navegador de Internet tiver esta função. Ao desligar o acesso a estas tecnologias, talvez o site não funcione corretamente.
</p>

<h1>Mídia incorporada de outros sites</h1>

<p>
Partes deste site podem incluir conteúdo incorporado como, por exemplo, vídeos, imagens, artigos, etc. Conteúdos incorporados de outros sites se comportam exatamente da mesma forma como se o visitante estivesse visitando o outro site, portanto estão sujeitos aos termos destes conteúdos.

Estes sites podem coletar dados sobre você, usar cookies, incorporar rastreamento adicional de terceiros e monitorar sua interação com este conteúdo incorporado, incluindo sua interação com o conteúdo incorporado se você tem uma conta e está conectado com o site.
</p>

<h1>O que fazemos com os seus dados</h1>

<p>
Os seus dados podem ser usados para fins de estatísticas de uso, análises de conversão e outras análises básicas ou avançadas de marketing e relacionamento. Sempre que possível os dados serão tratados anonimamente, ou seja, você não poderá ser diretamente identificado por eles (exceto pelo seu endereço IP ou e-mail em alguns casos). Para cadastros e dados fornecidos explicitamente por você, por exemplo formulários de contato ou de compras, você concorda que poderemos fazer análise segmentada por usuário, apenas com a finalidade de lhe fornecer um serviço mais adequado e de melhor qualidade, ou mais seguro. Caso não concorde com isso, solicite a exclusão dos seus dados.
</p>


<h1>Com quem partilhamos seus dados</h1>

<p>
Nós não partilhamos explicitamente seus dados ou estatísticas, nem cedemos informações a terceiros. Porém podemos fazer uso de ferramentas analíticas de perfil de usuário, como Google Analytics, para análise de tráfego e segmentação de público, para a finalidade de melhorarmos nosso serviço. Tanto quanto possível os dados são anônimos, salvo cadastros necessários para seu uso do site, como nome de usuário, senha, ou cadastros de cliente - fornecidos expressamente por você, ou coisas fora de nosso controle ou inerentes às tecnologias implementadas. Se o site exibir anúncios, seu perfil poderá estar sendo rastreado, se você fez logon em algum serviço vinculado a esta rede de anúncios.
</p>

<h1>Por quanto tempo mantemos os seus dados</h1>

<p>
Se você deixar um comentário, fizer um cadastro em nosso site, enviar um contato ou fizer uma compra, o comentário e os seus metadados e cadastros são conservados indefinidamente. Fazemos isso para que seja possível reconhecer e facilitar qualquer comentário ou contato posterior, além de permitir sua identificação em futuras compras. Se você consentir expressamente, estes dados poderão ser utilizados para nossas iniciativas de comunicação e marketing.
</p>

<h1>Quais os seus direitos sobre seus dados</h1>

<p>
Se você tiver uma conta neste site ou se tiver deixado comentários, cadastros ou contatos, pode solicitar um arquivo exportado dos dados pessoais que mantemos sobre você, inclusive quaisquer dados que nos tenha fornecido. Também pode solicitar que removamos qualquer dado pessoal que mantemos sobre você. Isto não inclui nenhum dado que somos obrigados a manter para propósitos administrativos, legais ou de segurança. Você também tem o direito de solicitar exclusão de qualquer iniciativa de comunicação ou de marketing.
</p>

<h1>Procedimentos de segurança</h1>

<p>
Mantemos senhas seguras e software atualizado em nosso servidor, além de serviços de monitoramento de segurança, e firewall de rede. Em caso de vazamento ou violação de dados, tão logo quanto possível re-geramos todas as senhas, atualizamos o software do site e avisamos por e-mail aos usuários que tenham acesso cadastrado.
</p>          
</TextGrid>
</PoliticaBanner>


    </>
  )
}