import React, {} from 'react';

import { BrowserRouter } from "react-router-dom";

import { AppRoutes } from "./app.routes";


export function Routes() {
    return(
        <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
        <AppRoutes />
        </BrowserRouter>
    );
}