import React, { useState } from 'react';
import api from '../../services/api.js';
import { FormContainer, 
    FormField,
    FieldWrapper,
    FormButton, 
    FormTextArea, 
    TextAreaWrapper,
    ButtonWrapper } from './Form.js';

import Modal from '../Modal/Modal.jsx';


export function Form({ pagina, ...rest }) {
  const [nome, setNome] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [mensagem, setMensagem] = useState('');

  const [sending, setSending] = useState(false);
  const [modalOpen, setModalOpen] = useState(false); 


 
  const Submit = (event) => {
    event.preventDefault();
    setSending(true);
    
    const data = {
      pagina: pagina, 
      nome, 
      empresa,
      telefone,
      email,
      mensagem
  };

    api.post(`/notifications/ultragraph/`, data).then(res => { 
      setSending(false); 
      setModalOpen(true);
      Clean();
      
    }).catch((error) => {
      setSending(false);
      console.log(error);
    });
  };

  const Clean = () => {
    setNome('');
    setEmpresa('');
    setTelefone('');
    setEmail('');
    setMensagem('');
  };

  return (
    <>
    <FormContainer {...rest} maxWidth='sm'>
      <form id='form' onSubmit={Submit}>

        <FieldWrapper>
          <FormField
            id='nome'
            name='nome'
            type='text'
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            label='Nome'
            variant='outlined'
            required />
        </FieldWrapper>

        <FieldWrapper>
          <FormField
            id='empresa'
            name='empresa'
            type='text'
            value={empresa}
            onChange={(e) => setEmpresa(e.target.value)}
            label='Empresa'
            variant='outlined'
             />
        </FieldWrapper>

        <FieldWrapper>
          <FormField
            id='email'
            name='email'
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
             label='E-mail'
            variant='outlined'
            required />
        </FieldWrapper>

        <FieldWrapper>
          <FormField
            id="telefone"
            name='telefone'
            type='tel'
            pattern="(\+?\d+)?"
            value={telefone}
            onChange={(e) => setTelefone(e.target.value)}
            label='Telefone'
            variant='outlined'
            required />
        </FieldWrapper>

        <TextAreaWrapper>
          <FormTextArea
            id='mensagem'
            name='mensagem'
            value={mensagem}
            onChange={(e) => setMensagem(e.target.value)}
            label='Mensagem'
            multiline
            minRows={4}
            maxRows={4}
            required
          />
        </TextAreaWrapper>

        <ButtonWrapper>
          <FormButton type="submit" form='form' disabled={sending}>
            {sending ? 'ENVIANDO...' : 'ENVIAR'}
          </FormButton>
        </ButtonWrapper>

      </form>
    </FormContainer>

<Modal open={modalOpen} onClose={() => setModalOpen(false)} />
   </>
  );
};
