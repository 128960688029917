import { createGlobalStyle } from 'styled-components';
export default createGlobalStyle`


@font-face {
  font-family: 'Baloo'; 
  src: url('./fonts/Baloo-Regular.ttf');
}

@font-face {
  font-family: 'Mont-Black'; 
  src: url('./fonts/Montserrat-Black.otf');
}

@font-face {
  font-family: 'Mont-Bold'; 
  src: url('./fonts/Montserrat-Bold.otf');
}

@font-face {
  font-family: 'Mont-Medium'; 
  src: url('./fonts/Montserrat-Medium.otf');
}

@font-face {
  font-family: 'Mont-Regular'; 
  src: url('./fonts/Montserrat-Regular.otf');
}

@font-face {
  font-family: 'Mont-Light'; 
  src: url('./fonts/Montserrat-Light.otf');
}




* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 62.5%;

    ::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment  {
	display: none;
}

::-webkit-scrollbar-track-piece  {
	background-color: #c2c9cb; 
}

::-webkit-scrollbar-thumb:vertical {
	background-color: #285776; 
  border-radius: 1rem;
}

}

html {
  scroll-behavior: smooth;
}


body {
    color: ${({ theme }) => theme.COLORS.BLUE};
    font-size: 1.6rem;
}

a, button {  
    text-decoration: none;
    cursor: pointer;
}


ul {
   list-style-type: none;
}
`;