import styled from "styled-components";
import { Grid, Container } from "@mui/material";

export const Titulos = styled.h1`
margin-top: 6rem;
margin-bottom: 2rem;
text-align: center;
animation: fadeIn 2s ease-in-out;
font-size: clamp(2.8rem,  4vw, 4rem); 
font-family: 'Baloo';


  @media (max-width: 1200px) { 
        white-space: break-spaces;
        
  }
`;


export const PoliticaBanner = styled(Container)`
padding: 4rem 0;
text-align: center;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

`;


export const TextGrid = styled(Grid)`
animation: fadeIn 2s ease-in-out;
h1 {
  color: ${({ theme }) => theme.COLORS.CYAN};
  font-family: 'Mont-Bold'; 
  font-size: 5rem;
  font-size: clamp(2rem,  4.5vw, 3rem); 
  margin-bottom: 2rem;
}

p {
  font-family: 'Mont-Light'; 
  font-size: clamp(1.5rem,  4.5vw, 2.2rem); 
  letter-spacing: 1.5px;
  margin-bottom: 6rem;
  line-height:clamp(2.4rem,  4.5vw, 3rem); 
}



`;



