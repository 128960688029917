import React, { useState } from 'react';
import { StyledContainer, StyledGrid, OutsideLink, Line, Bottom } from './Footer.js';


import FooterLogo from "../../assets/rodape-logo.png"

import LogoFaceOn from '../../assets/icon-fb-on.png'
import LogoFaceOff from '../../assets/icon-fb-off.png'

import LogoInstaOn from '../../assets/icon-ig-on.png'
import LogoInstaOff from '../../assets/icon-ig-off.png'

import LogoLinkedinOn from '../../assets/icon-ld-on.png'
import LogoLinkedinOff from '../../assets/icon-ld-off.png'


import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';

export function Footer() {
    const [isFacebookHovered, setIsFacebookHovered] = useState(false);
    const [isInstaHovered, setIsInstaHovered] = useState(false);
    const [isLinkedinHovered, setIsLinkedinHovered] = useState(false);


    const handleMouseOverFace = () => {
        setIsFacebookHovered(true);
      };

    const handleMouseOutFace = () => {
        setIsFacebookHovered(false);
      };
      
    
      const handleMouseOverInsta = () => {
        setIsInstaHovered(true);
      };

      const handleMouseOutInsta = () => {
        setIsInstaHovered(false);
      };

      const handleMouseOverLinkedin = () => {
        setIsLinkedinHovered(true);
      };

      const handleMouseOutLinkedin = () => {
        setIsLinkedinHovered(false);
      };

    return (
      <>

<StyledContainer maxWidth="xg">
      <Grid container>
      <StyledGrid item xs={12} sm={12}  md={4}>

           <div className='logo'>
           <Link to="/"><img src={FooterLogo} alt="Logo" /></Link>
           </div>
        </StyledGrid>



        <StyledGrid item xs={12} sm={12}  md={2}>
        <h1>CONTATO</h1>
         
         <ul>
            <li> <OutsideLink href="tel:1198259.8002"><span>(11) 98259.8002</span></OutsideLink></li>
         </ul>
        </StyledGrid>


       


        <StyledGrid item xs={12} sm={12}  md={2}>
        <h1>REDES SOCIAIS</h1>
       
       <OutsideLink href="https://www.facebook.com/ulltragraph.br" target="_blank" onMouseOver={handleMouseOverFace} onMouseOut={handleMouseOutFace}>
              <img style={{ width: 30 }} src={isFacebookHovered ? LogoFaceOn : LogoFaceOff} border="0"/>
            </OutsideLink>
      
            <OutsideLink href="https://instagram.com/ultragraph.br" target="_blank" onMouseOver={handleMouseOverInsta} onMouseOut={handleMouseOutInsta}>
              <img style={{ width: 30, marginRight:'1.4rem', marginLeft: '1.4rem' }} src={isInstaHovered ? LogoInstaOn : LogoInstaOff} border="0"/>
            </OutsideLink>
      
            <OutsideLink href="https://www.linkedin.com/company/ultragraph-br" target="_blank" onMouseOver={handleMouseOverLinkedin} onMouseOut={handleMouseOutLinkedin}>
              <img style={{ width: 30 }} src={isLinkedinHovered ? LogoLinkedinOn : LogoLinkedinOff} border="0"/>
            </OutsideLink>
        </StyledGrid>


        <StyledGrid item xs={12} sm={12}  md={4}>
        <h1>ENDEREÇO</h1>

        <p>
        R. Célso Morato Leite, 600 - Bloco E<br/>
        Distrito Industrial Hatsuta, Agudos - SP<br/>
        17123-220
        </p>
        </StyledGrid>


       
      </Grid>

      <Grid container>
      <StyledGrid item xs={12} sm={12}  md={12}>
        <Line/>
      <Bottom>
      <ul>
     <li> 
          <OutsideLink target='_blank' href="https://ultragraph.com.br/politicaprivacidade">
     <span className='politica'>Política de Privacidade</span>
          </OutsideLink>
     </li>
      </ul>
      </Bottom>
      </StyledGrid>
      </Grid>

         

</StyledContainer>

     </>
    )
  }