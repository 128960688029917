import React from 'react';
import { ModalAviso, Title, Text } from './Modal.js';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function Modal({ open, onClose }) {
  return (
    <React.Fragment>
      <ModalAviso
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Title>SUCESSO</Title>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{padding: '4rem 2rem'}} dividers>
          <Text>
            Seus dados foram enviados com sucesso!
          </Text>
        
        </DialogContent>
      </ModalAviso>
    </React.Fragment>
  );
}
