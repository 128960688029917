import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components'; 

import { Routes } from './routes';

import theme from './styles/theme';
import GlobalStyles from './styles/global';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
    <GlobalStyles/>
   
        <Routes />
     
    </ThemeProvider>
  </React.StrictMode>
);


