import styled from "styled-components";

import { Container, TextField } from "@mui/material";

export const FormContainer = styled(Container)`
   margin: 4rem 0;

   form {
    display: flex;
    flex-direction: column;
    align-items: left;
    color: ${({ theme }) => theme.COLORS.BLUE};
   }
`;


export const FieldWrapper = styled.div`
   margin-bottom: 1.8rem;
`;


export const FormField = styled(TextField)`
width: 100%;

.MuiInputLabel-root {
  color: ${({ theme }) => theme.COLORS.BLUE};
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: -.5rem;


  font-size: clamp(1.8rem,  4vw, 2rem) !important; 
  background-color:  ${({ theme }) => theme.COLORS.WHITE};
  font-family: 'Mont-Bold';
};

.MuiInputLabel-root.Mui-focused {
  color: ${({ theme }) => theme.COLORS.BLUE};
};



.MuiOutlinedInput-root {
  border-radius: 999px;

  padding: 0 1.2rem;
  font-family: 'Mont-Light'; 
  font-size: 1.4rem !important;
  color: ${({ theme }) => theme.COLORS.BLUE};

  fieldset {
    border-color: ${({ theme }) => theme.COLORS.BLUE};
  } 
}
`;


export const TextAreaWrapper = styled.div`
`;


export const FormTextArea = styled(TextField)`
  width: 100%;
 
  .MuiInputLabel-root {
  color: ${({ theme }) => theme.COLORS.BLUE};
  padding-left: 1rem;
  padding-right: 1rem;
 
  font-family: 'Mont-Bold';

  font-size: clamp(1.8rem,  4vw, 2rem) !important; 
  background-color:  ${({ theme }) => theme.COLORS.WHITE};
};

.MuiInputLabel-root.Mui-focused {
  color: ${({ theme }) => theme.COLORS.BLUE};
};


  .MuiOutlinedInput-root {
  border-radius: 1.2rem;
  padding: 2.4rem;

  font-family: 'Mont-Light'; 
  font-size: 1.6rem !important;
  color: ${({ theme }) => theme.COLORS.BLUE};

  fieldset {
    border-color: ${({ theme }) => theme.COLORS.BLUE};   
  }
}
`;


export const ButtonWrapper = styled.div`
text-align: right;


@media (max-width: 900px) {
  text-align: center;
}
`;


export const FormButton =styled.button`
    padding: 1.1rem 12%;
    border: none;
    border-radius: 999px;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;

    background-color:${({ theme }) => theme.COLORS.CYAN};
    color: ${({ theme }) => theme.COLORS.WHITE};
    font-size: clamp(1.4rem, 2vw, 3rem);
    font-family: 'Mont-Bold';
    letter-spacing: 2px;

    transition: all .1s;
    
    &:hover {
        background-color:${({ theme }) => theme.COLORS.BLUE};
        color: ${({ theme }) => theme.COLORS.WHITE};
    }
    
`;