import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Box, Grid } from "@mui/material";
import { initSVG, MainBanner, MainGrid, UltragraphGrid, VergalhoesGrid, FormBanner, AnimationContainer } from "./Home.js";

import { Footer } from '../../components/Footer/Footer.jsx';
import { Form } from "../../components/Form/Form.jsx"

import HeaderLogo from "../../assets/cabecalho-logo.png"
import Imagem1 from "../../assets/sessao1-elemento.png"
import Imagem2 from "../../assets/pagina-elementos.png"

import LogoFaceOn from '../../assets/icon-fb-on.png'
import LogoFaceOff from '../../assets/icon-fb-off.png'

import LogoInstaOn from '../../assets/icon-ig-on.png'
import LogoInstaOff from '../../assets/icon-ig-off.png'

import LogoLinkedinOn from '../../assets/icon-ld-on.png'
import LogoLinkedinOff from '../../assets/icon-ld-off.png'



export function Home() {
  const vergalhoesRef = useRef(null);


  useEffect(() => {
    initSVG(); // Chama a função initSVG ao montar o componente
  }, []); // O array vazio [] garante que o useEffect seja executado apenas uma vez

  useEffect(() => {
    const handleScroll = (e) => {
      e.preventDefault();
      const targetId = e.currentTarget.getAttribute('href').slice(1);
      const targetElement = document.getElementById(targetId);
      
      if (targetElement) {
        const yOffset = -80; // Ajuste o valor para o offset desejado
        const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    };

    const anchor = document.querySelector('a[href="#vergalhoes"]');
    anchor.addEventListener('click', handleScroll);

    return () => {
      anchor.removeEventListener('click', handleScroll);
    };
  }, []);


    const [isFacebookHovered, setIsFacebookHovered] = useState(false);
    const [isInstaHovered, setIsInstaHovered] = useState(false);
    const [isLinkedinHovered, setIsLinkedinHovered] = useState(false);

     const handleMouseOverFace = () => {
        setIsFacebookHovered(true);
      };

     const handleMouseOutFace = () => {
        setIsFacebookHovered(false);
      };  
    
      const handleMouseOverInsta = () => {
        setIsInstaHovered(true);
      };

      const handleMouseOutInsta = () => {
        setIsInstaHovered(false);
      };

      const handleMouseOverLinkedin = () => {
        setIsLinkedinHovered(true);
      };

      const handleMouseOutLinkedin = () => {
        setIsLinkedinHovered(false);
      };


    return (
      <>
      <MainBanner>
        <Box className='header'>
        <div>
            <Link to="/"><img className='logo' src={HeaderLogo} alt="Logo" /></Link>
        </div>

        <div>
      <a href="https://www.facebook.com/ulltragraph.br" target="_blank" onMouseOver={handleMouseOverFace} onMouseOut={handleMouseOutFace}>
        <img style={{ width: 30 }} src={isFacebookHovered ? LogoFaceOn : LogoFaceOff} border="0"/>
      </a>

      <a href="https://instagram.com/ultragraph.br" target="_blank" onMouseOver={handleMouseOverInsta} onMouseOut={handleMouseOutInsta}>
        <img style={{ width: 30, marginRight:'1.4rem', marginLeft: '1.4rem' }} src={isInstaHovered ? LogoInstaOn : LogoInstaOff} border="0"/>
      </a>

      <a href="https://www.linkedin.com/company/ultragraph-br" target="_blank" onMouseOver={handleMouseOverLinkedin} onMouseOut={handleMouseOutLinkedin}>
        <img style={{ width: 30 }} src={isLinkedinHovered ? LogoLinkedinOn : LogoLinkedinOff} border="0"/>
      </a>
        </div>
        </Box>


        <MainGrid container>
<Grid className='sideA' item xs={12} md={6}>
<h2>Somos o</h2>
<h1>Futuro</h1> 
<h2>da construção civil</h2>

<h3>Vergalhão com grafeno:</h3>
<h4>Propriedades únicas ao<br/>
seu alcance.</h4>

<a href="#vergalhoes">SAIBA MAIS</a>  
</Grid>

<Grid className='sideB' item xs={12} md={6}>
<img src={Imagem1} alt="Logo" />
</Grid>
        </MainGrid> 
      </MainBanner>


      <UltragraphGrid container>
      <Grid className='sideA' item xs={12} md={6}>
<img src={Imagem2} />
</Grid>

<Grid className='sideB' item xs={12} md={6}>
<h1>Conheça a<br/>
Ultragraph</h1>

<p>
Somos uma empresa especializada em soluções de
grafeno e nanotecnologia para a indústria de
construção civil.<br/><br/>
Nos destacamos pela fabricação e comercialização
de vergalhões e compósitos poliméricos com aditivo
de grafeno. Nossa composição traz benefícios reais
aplicados a construção civil e toda a segurança que a
indústria exige. Os Vergalhões Ultragraph são muito
mais versáteis, sustentáveis e econômicos em relação
as soluções disponíveis no mercado.   
</p>
</Grid>

      </UltragraphGrid>


   
      <VergalhoesGrid id="vergalhoes" container>
      <Grid className='sideA' item xs={12} md={5} xl={6}>
      <h1>Vergalhões Ultragraph</h1>

      <p>
      Os vergalhões Ultragraph propiciam uma vida útil
prolongada às estruturas de concreto armado,
especialmente em ambientes agressivos, pois suas
fibras e matriz não são corrosivas.

<span className='short-text'><br/><br/>
O vergalhão de fibra de vidro (GFRP) é uma alternativa
ao aço na construção civil, oferecendo vantagens
significativas em durabilidade e desempenho, que
juntamente com o grafeno, potencializa ainda mais
suas propriedades.
</span>
      </p>
      </Grid>

      
 
<Grid className='sideB' item xs={12} md={7} xl={6}>

<AnimationContainer>
      <svg width="800" height="800" id="svgCanvas">
        <polyline className="line1 " fill="none"></polyline>
        <polyline className="line2 " fill="none"></polyline>
        <polyline className="line3 " fill="none"></polyline>
        <polyline className="line4 " fill="none"></polyline>
      </svg>

      <div className="point A"></div>
      <div className="point B"></div>
      <div className="point C"></div>

      <div className="point D"></div>
      <div className="point E"></div>
      <div className="point F"></div>

      <div className="point G"></div>
      <div className="point H"></div>
      <div className="point I"></div>

      <div className="point J"></div>
      <div className="point K"></div>
      <div className="point L"></div>
    </AnimationContainer>
</Grid>

<Grid className='sideC' item xs={12} md={12}>
<p>
<span className='long-text'>
O vergalhão de fibra de vidro (GFRP) é uma alternativa
ao aço na construção civil, oferecendo vantagens
significativas em durabilidade e desempenho, que
juntamente com o grafeno, potencializa ainda mais
suas propriedades.<br/><br/>
</span>

A utilização do aço na construção civil tem sido altamente eficaz apesar de suas desvantagens, como baixa
resistência à corrosão e vida útil limitada, levando os engenheiros a buscar alternativas. Os compósitos de fibra,
como o vergalhão de fibra de vidro (GFRP), surgem como uma excelente opção, oferecendo propriedades físicas
desejáveis e vantagens sobre o aço.<br/><br/>

Após extenso desenvolvimento e testes de certificação a ULTRAGRAPH lançou o "Vergalhão Ultragraph", uma barra
polimérica reforçada com fibra de vidro e resina com o aditivo de grafeno. Esta solução não só cumpre os
requisitos de sustentabilidade e durabilidade, como também proporciona uma ótima relação custo-benefício.<br/><br/>

Com um peso de aproximadamente 25% do vergalhão com a mesma bitola feito em aço, as propriedades do
vergalhão com grafeno são comprovadas para oferecer vantagens claras na economia, qualidade e na tecnologia
de construção.<br/><br/>

Por não serem condutores, são altamente adequados em ambientes sensíveis a eletricidade, campos magnéticos
ou condutividade térmica, além de permitirem uma menor cobertura de concreto, mantendo uma maior vida útil
do produto na construção.<br/><br/>

A sua redução de peso não significa uma menor durabilidade, muito pelo contrário, a adição de grafeno aos
vergalhões aumenta sua resistência e permite a detecção de danos estruturais por meio de sensores elétricos
embutidos. Também melhora a aderência vergalhão-concreto, aumentando a eficiência estrutural, e devido ao seu
peso reduzido, seu transporte e manipulação são extremamente facilitados durante a construção.
</p>
</Grid>
      </VergalhoesGrid>



<FormBanner>
<div className="form-text">
<h1>Entre em contato conosco!</h1>

<p>
Para dúvidas, informações e orçamentos,<br/>
preencha o formulário abaixo.
</p>
</div>

<Form pagina={'Ultragraph'} />
</FormBanner>



      <Footer/>

      </>
    )
  }