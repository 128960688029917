import styled from 'styled-components';

import { Container, Grid } from "@mui/material";

export const StyledContainer = styled(Container)`
  padding-top: 6rem;
  padding-bottom: 2rem;

  height: 100%;
  background-color: ${({ theme }) => theme.COLORS.BLUE};

  text-align: left;


  .politica {
    font-size: 1.4rem;
  }


 
  @media (max-width: 900px) {
   text-align: center;
  }



`;

export const StyledGrid = styled(Grid)`

.logo {
   
    text-align: center;
    img {
        width: clamp(24rem, 20vw, 44rem);
        margin-bottom: 4rem;
    }
}


  p {
    color: ${({ theme }) => theme.COLORS.WHITE};
    font-family: 'Mont-Medium';
    font-size:  clamp(1.6rem, 2vw, 1.8rem);
  }

 h1 {
    color: ${({ theme }) => theme.COLORS.CYAN};
    font-family: 'Mont-Bold'; 
    font-size:  clamp(1.6rem, 2vw, 1.8rem);
    margin-bottom: .8rem;
    margin-top: 2rem;
}


@media (max-width: 900px) {
.logo {
    padding: 0;
}


h1 {
    margin-top: 4rem;
}
  }
 
`;

export const OutsideLink = styled.a`
 text-decoration: none !important;

 span {
    color: ${({ theme }) => theme.COLORS.WHITE};
    font-family: 'Mont-Medium';
    font-size:  clamp(1.6rem, 2vw, 1.8rem);
}

 span:hover {
    color: ${({ theme }) => theme.COLORS.CYAN}; 
}
 
`;


export const Line = styled.div`
border-top: 1px solid ${({ theme }) => theme.COLORS.CYAN};
margin-left: auto;
margin-right: auto;

margin-top: 10rem;
margin-bottom: 2rem;

width: 65%;

@media (max-width: 600px) {
    width: 100%;
  }
`;


export const Bottom = styled.div`
text-align: center;
`;




